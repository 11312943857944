import {
  variables,
} from "../api/Variables.js";
import React, { Component, useState, useEffect, useRef } from "react";


import Main from './Main.jsx';
import MainAdmin from './MainAdmin.jsx';


const Page = () => {
    const [token] = useState(variables.token);
    const [is_admin] = useState(variables.is_admin);

    if (is_admin)
        return (<MainAdmin />)
    else
        return (<Main />)
}

export default Page;
