import React, { Component, useState, useEffect, useRef } from "react";

import { useNavigate, Link } from "react-router-dom";

import InfoPanel from "../components/InfoPanel";
import ControlPanel from "../components/ControlPanel";

import AsyncSelect from "react-select/async";
import Select from "react-select";
import ReactSelect, { createFilter } from "react-select";

import { Tab } from "@headlessui/react";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";

import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import Aside from "../components/Sidebar";

import { Disclosure } from "@headlessui/react";
import axios from "axios";

import ReactPaginate from "react-paginate";
import Slider from "react-input-slider";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import "../assets/ag-theme-acmecorp.css";
import MyComponent from "../components/Chart.jsx";
import Chart2 from "../components/Chart2.jsx";
import Chart3 from "../components/Chart3.jsx";

import Gr1 from "../../src/gr1.png";
import Gr2 from "../../src/gr2.png";

import {
  variables,
  showDate,
  GetDatasetParams,
  AG_GRID_LOCALE_RU,
} from "../api/Variables.js";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


const Main = () => {
    const navigate = useNavigate();
    const gridRef = useRef(null);
    const datasetGridRef = useRef(null);

    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [formUsername, setUsername] = useState("");
    const [formPassword, setPassword] = useState("");
    const [token, setToken] = useState(variables.token);
//     const [token] = useState("a0fed4dd-3537-4e88-b105-88551fc13e26")

    const [columnsStates] = useState([
        {
            field: "NAME",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Название файла",

        },
        {
            field: "TYPE",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Тип задачи",
            cellRenderer: (params) => {
                switch (params.getValue()) {
                    case 1:
                        return 'Глюкоза'
                    case 2:
                        return 'Гемоглобин'
                }
            },
        },
        {
            field: "CREATE_DATE",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Дата загрузки",
            cellRenderer: (params) => {
                if (params.getValue() === null || params.getValue() === undefined)
                  return <div></div>;
                return showDate(params.getValue());
            },
        },
        {
            field: "USER_NAME",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Владелец",
        }
    ])
    const [rowData, setRowData] = useState([])
    const [createTypeLearn, changeCreateTypeLearn] = useState(null)
    const [currentDataset, setCurrentDataset] = useState(0)

    const [currentPage, setCurrentPage] = useState(0)

    const [currentImageUser, setCurrentImageUser] = useState(null)
    const [currentImageDist, setCurrentImageDist] = useState(null)

    const [currentMetrics, setCurrentmetrics] = useState([])
    const [currentTest, setCurrentTest] = useState(null)

    const [currentBlan, setCurrentBlan] = useState(null)
    const [currentImageBlan, setCurrentImageBlan] = useState(null)

    const [currentRes, setCurrentRes] = useState(null)
    const [currentImageRes1, setCurrentImageRes1] = useState(null)
    const [currentImageRes2, setCurrentImageRes2] = useState(null)

    const [datasetRows, setDatasetRows] = useState([])
    const [datasetColumns] = useState([
        {
            field: "RefValues",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Референтное значение",
        },
        {
            field: "Values",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Значение",
        },
        {
            field: "Midle",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Среднее",
        },
        {
            field: "Error",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Ошибка",
        },
        {
            field: "Proc_err",
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
            resizable: true,
            headerName: "Референтная ошибка",
        }
    ])


    const loadList = async(e) => {
        changeCreateTypeLearn(e);
        await axios.get(variables.API_URL + `/measure/files?Type=${e?.id}`,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setRowData(response.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
    }

    const uploadClick = async(e) => {
        e.preventDefault();
        let format = e.target.files[0].name.split(".").slice(-1)[0];
        if (format != "csv" && format != "xlsx" && format != "xls") {
          alert('Загрузите пожайлуста файлы формата ".csv" или ".xlsx"');
          return;
        }
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);

        await axios.post(
          variables.API_URL +
            `/measure/load_file?Type=${createTypeLearn.id}`,
          formData,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        )
          .then((response) => {
            if (response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error('ошибка при добавлении файла')
            }
            loadList(createTypeLearn)
          })
          .catch((error) => {
            alert("Ошибка");
          });
    };

    const loadDataset = async(e) => {
        await axios.get(variables.API_URL + `/measure/export?ID=${e}`,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setDatasetRows(response.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });

        setCurrentPage(0)
        setCurrentImageBlan(null)
        setCurrentBlan(null)

        setCurrentImageUser(null)
        setCurrentImageDist(null)

        setCurrentmetrics(null)

        setCurrentTest(null)

        setCurrentRes(null)
        setCurrentImageRes1(null)
        setCurrentImageRes2(null)
    }

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        const currentDataset = selectedRows.length === 1 ? selectedRows[0] : null;
        setCurrentDataset(currentDataset.ID)
        loadDataset(currentDataset.ID)
    };

    useEffect(() => {
        loadList({ id: 1, name: "Глюкоза" })
    }, []);

    useEffect(() => {
        if (token === null || token === '')
            navigate("/login")
    }, [token]);

    const loadMeasure = async(e) => {
        setCurrentPage(1)
        await axios.get(variables.API_URL + `/measure/measures/user?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentImageUser(response.data.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
        await axios.get(variables.API_URL + `/measure/measures/distribution?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentImageDist(response.data.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
    }

    const loadMetricts = async(e) => {
        setCurrentPage(2)
        await axios.get(variables.API_URL + `/measure/metrics?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentmetrics(response.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
    }

    const loadBland = async(e) => {
        setCurrentPage(5)
        await axios.get(variables.API_URL + `/measure/bland-altman?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentImageBlan(response.data.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
        await axios.get(variables.API_URL + `/measure/bland-altman-metrics?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentBlan(response.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });
    }

    const loadVariable = async(e) => {
        setCurrentPage(3)
        await axios.get(variables.API_URL + `/measure/test-shapiro-vilk?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentTest(response.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
    }

    const loadResult = async(e) => {
        setCurrentPage(4)
        await axios.get(variables.API_URL + `/measure/results/metrics?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentRes(response.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
        await axios.get(variables.API_URL + `/measure/results/graphic1?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentImageRes1(response.data.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
        await axios.get(variables.API_URL + `/measure/results/graphic2?ID=${currentDataset}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        })
          .then(function (response) {
            console.log(response.data);
            if(response.status !== 200) {
                if (response.status === 401)
                    setToken('')
                throw Error(`${response.data.message}`);
            }
            setCurrentImageRes2(response.data.data)
          })
          .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
          });
    }

    return (
        <div className="flex h-screen overflow-hidden">
          {/*Контент*/}
          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <header className="bg-white px-4 py-1 dark:bg-gray-800 border-b border-gray-200">
              <nav class="flex" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                  <li class="inline-flex items-center">
                    <a
                      href="#"
                      class="p-2 rounded-lg inline-flex items-center text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                    >
                      <svg
                        class="w-3 h-3 mr-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                      </svg>
                      Главная
                    </a>
                  </li>
                  <li>
                    <Link to="/">
                      <div class="flex items-center">
                        <svg
                          class="w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <a
                          href="#"
                          class="p-2 rounded-lg ml-1 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                        >
                          Обычный доступ
                        </a>
                      </div>
                    </Link>
                  </li>
                </ol>
              </nav>
            </header>
            <Tab.Group>
              <div>
                <Disclosure
                  as="nav"
                  className="bg-white border-gray-200 px-4 dark:bg-gray-800"
                >
                  {({ open }) => (
                    <div className="flex h-16 items-center justify-between">
                      <div className="flex items-center">
                        <div className="hidden md:block">
                          <div className="flex items-baseline space-x-1">
                            <Tab.List className="flex text-sm font-medium text-center">
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "",
                                    "inline-block p-2 border-b-2 rounded-t-lg",
                                    selected
                                      ? "focus:outline-none text-blue-600 border-b-2 border-blue-600"
                                      : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                                  )
                                }
                              >
                                Загрузка данных
                              </Tab>
                              <Tab
                                className={({ selected }) =>
                                  classNames(
                                    "",
                                    "inline-block p-2 border-b-2 rounded-t-lg",
                                    selected
                                      ? "focus:outline-none text-blue-600 border-b-2 border-blue-600"
                                      : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                                  )
                                }
                              >
                                Просмотр и расчет
                              </Tab>
                            </Tab.List>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Disclosure>
                {/* Страница с датасетом где он выводится в aj-grid и тут его загрузка есть */}
                <Tab.Panels className={classNames("px-4")}>
                  <Tab.Panel
                    className={classNames(
                      "h-dvh flex flex-col bg-white dark:bg-gray-800",
                      "focus:outline-none"
                    )}
                  >
                    {/* загрузка файла */}
                    <InfoPanel>
                      <span class="sr-only">Info</span>
                      <div class="ml-3 text-sm">
                        Это стартовая страница. Чтобы начать работу в системе,
                        выберите тип решаемой задачи и загрузите файл с
                        соответсвующим датасетом. Обратите внимание, что файл
                        должен соответствовать следующим параметрам:{" "}
                        <p>• Допустимый формат: XLS, XLSX, CSV </p>
                        <p>• Допустимый размер: до 10 MB</p>
                        <p>
                          Если вы хотите протестировать работу системы, но у вас
                          нет готового датасета, вы можете воспользоваться
                          нашими тестовыми датасетами. Для этого кликните одну
                          из строк ниже и нажмите кнопку "Анализировать"
                        </p>
                      </div>
                    </InfoPanel>
                    <div className="mb-4">
                      <label class="mb-3 block">Выбор конкурса:</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={[
                          { id: 1, name: "Глюкоза" },
                          { id: 2, name: "Гемоглобин" },
                        ]}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option}
                        value={createTypeLearn}
                        onChange={(e) => loadList(e)}
                        placeholder="Выберите тип решаемой задачи"
                        className="w-96"
                      />
                    </div>
                    <div className="">
                      <span>
                        <label
                            className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            htmlFor="files"
                        >
                            Загрузить датасет
                        </label>
                      </span>
                      <input
                        className="hidden"
                        id="files"
                        type="file"
                        fileType="Классифиция"
                        onChange={uploadClick}
                      />
                      <br />
                      <br />
                    </div>
                    {/* просмотр и выбор датасетов */}
                    <div className="mt-2 grow w-full flex text-sm leading-6 text-gray-600">
                      <div
                        className="ag-theme-alpine ag-theme-acmecorp"
                        style={{ height: "100%", width: "100%" }}
                      >
                        <AgGridReact
                          ref={gridRef}
                          rowData={rowData}
                          columnDefs={columnsStates}
                          rowSelection={"single"}
                          onSelectionChanged={onSelectionChanged}
                        />
                      </div>
                    </div>
                  </Tab.Panel>
                  <Tab.Panel
                    className={classNames(
                      "h-dvh flex flex-col bg-white dark:bg-gray-800",
                      "focus:outline-none"
                    )}
                  >
                    {/* Отображаем файл в виде таблицы */}
                    <InfoPanel>
                      <span class="sr-only">Info</span>
                      <div class="ml-3 text-sm">
                        В этом разделе отображается содержание файла. Здесь вы
                        можете просматривать и редактировать данные.
                      </div>
                    </InfoPanel>
                    {true ?  (
                      <div className="grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                        <div className="col-span-8">
                          <div className="grow w-full flex flex-col text-sm leading-6 text-gray-600">
                            <div
                              className="ag-theme-alpine ag-theme-acmecorp flex flex-col"
                              style={{ height: "600px", width: "100%" }}
                            >
                              <AgGridReact
                                ref={datasetGridRef}
                                columnDefs={datasetColumns}
                                rowData={datasetRows}
                                localeText={AG_GRID_LOCALE_RU}
                                pagination={true}
                                autosize={true}
                                sideBar={{
                                  toolPanels: [
                                    {
                                      id: "columns",
                                      labelDefault: "Columns",
                                      labelKey: "columns",
                                      iconKey: "columns",
                                      toolPanel: "agColumnsToolPanel",
                                      minWidth: 225,
                                      width: 225,
                                      maxWidth: 225,
                                    },
                                    {
                                      id: "filters",
                                      labelDefault: "Filters",
                                      labelKey: "filters",
                                      iconKey: "filter",
                                      toolPanel: "agFiltersToolPanel",
                                      minWidth: 180,
                                      maxWidth: 400,
                                      width: 250,
                                    },
                                  ],
                                  position: "left",
                                }}
                              />
                            </div>
                            <div className="flex md:mt-6 md:gap-6">
                              <button
                                className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                htmlFor="files"
                                onClick={(e) => loadMeasure(e)}
                              >
                                Измерения
                              </button>
                              <button
                                className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                htmlFor="files"
                                onClick={(e) => loadMetricts(e)}
                              >
                                Метрики
                              </button>
                              <button
                                className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                htmlFor="files"
                                onClick={(e) => loadBland(e)}
                              >
                                график Бланда-Альтмана
                              </button>
                              <button
                                className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                htmlFor="files"
                                onClick={(e) => loadVariable(e)}
                              >
                                Проверить на нормальность
                              </button>
                              <button
                                className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                htmlFor="files"
                                onClick={(e) => loadResult(e)}
                              >
                                Провести оценку
                              </button>
                            </div>
                          </div>

                        </div>
                        {currentPage === 0 ?
                            null
                        : currentPage === 1 ?
                        <div className="col-span-4 flex flex-col md:gap-6">
                          <div class="rounded-md border border-gray-200 bg-gray-100 py-6 px-6 shadow-default">
                            {currentImageUser ?
                                <img src={`data:image/png;base64,${currentImageUser}`} />
                            : null}
                          </div>
                          <div class="rounded-md border border-gray-200 bg-gray-100 py-6 px-6 shadow-default">
                            {currentImageDist ?
                                <img src={`data:image/png;base64,${currentImageDist}`} />
                            : null}
                          </div>
                        </div>
                        : currentPage === 2 ?
                        <div className="col-span-4 max-w-60">
                            {currentMetrics?.map(row => (
                                <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                    <div class="flex items-end justify-between">
                                       <div>
                                          <h5 class="text-3xl font-bold text-black dark:text-white">
                                            {row.value}
                                          </h5>
                                          <span class="text-sm font-medium">
                                            {row.name}
                                          </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        : currentPage === 3 ?
                        <div className="col-span-4 max-w-60">
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentTest?.is_normal}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          Подчинен нормальному распредлению
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentTest?.p}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          P
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentTest?.w}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          W
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : currentPage === 4 ?
                        <div className="col-span-4 max-w-60">
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentRes?.z}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          Z
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentRes?.lower_ci}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          lower_ci
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentRes?.uper_ci}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          uper_ci
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentRes?.str_CI}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          МO ошибки в интервале
                                        </span>
                                    </div>
                                </div>
                            </div>
                          <div class="rounded-md border border-gray-200 bg-gray-100 py-6 px-6 shadow-default">
                            {currentImageRes1 ?
                                <img src={`data:image/png;base64,${currentImageRes1}`} />
                            : null}
                          </div>
                          <div class="rounded-md border border-gray-200 bg-gray-100 py-6 px-6 shadow-default">
                            {currentImageRes2 ?
                                <img src={`data:image/png;base64,${currentImageRes2}`} />
                            : null}
                          </div>
                        </div>
                        : currentPage === 5 ?
                        <div className="col-span-4 max-w-60">
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentBlan?.exact_UCI}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          exact_UCI
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentBlan?.exact_LCI}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          exact_LCI
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentBlan?.approx_UCI}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          approx_UCI
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentBlan?.approx_LCI}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          approx_LCI
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentBlan?.bootstrap_UCI}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          bootstrap_UCI
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border border-gray-200 bg-gray-100 py-1 px-1 shadow-default">
                                <div class="flex items-end justify-between">
                                    <div>
                                        <h5 class="text-3xl font-bold text-black dark:text-white">
                                         {currentBlan?.bootstrap_LCI}
                                        </h5>
                                        <span class="text-sm font-medium">
                                          bootstrap_LCI
                                        </span>
                                    </div>
                                </div>
                            </div>
                          <div class="rounded-md border border-gray-200 bg-gray-100 py-6 px-6 shadow-default">
                            {currentImageBlan ?
                                <img src={`data:image/png;base64,${currentImageBlan}`} />
                            : null}
                          </div>
                        </div>
                        : null
                        }
                      </div>
                    ) : null}
                  </Tab.Panel>
                </Tab.Panels>
              </div>
            </Tab.Group>
          </div>
        </div>
    );
}

export default Main;
